<template>
<b-modal :class="`text-color-${theme}`" content-class="border-radius" id="modal-new-helper-V2" hide-header hide-footer>
    <div class="modalContent">
        <slot name="modal-header-close">
            <div class="close" :class="`close-${theme}`" @click="hideModal">×</div>
        </slot>
        <slot name="modal-title">
            <p class="title" :class="`text-color-${theme}`">{{ $t('helper.modal.title') }}</p>
        </slot>
        <b-form-group class="groupModal" :class="`text-color-${theme}`" :label="$t('helper.modal.input1')" label-for="capa">
            <b-form-input class="inputTitle" :class="{'border-red': isTitleEmpty}" v-model="titleChamado" :placeholder="$t('helper.modal.input1')"></b-form-input>
        </b-form-group>
        <div style="position: relative;">
            <b-form-group class="groupModal" :class="`text-color-${theme}`" :label="$t('helper.modal.input2')" label-for="capa">
                <b-form-textarea class="textAreaModal" :class="{ 'border-red': descricaoChamadoExcedido }" id="textarea" v-model="descricaoChamado" :placeholder="$t('helper.modal.input2')+'...'" rows="5"></b-form-textarea>
                <p class="countLength" :style="{ color: descricaoChamado.length > 255 ? 'red' : '' }">
                    {{ descricaoChamado.length }} / 255
                </p>
            </b-form-group>
        </div>

        <div class="spaceFooter">
            <button class="btnSubmit" @click="saveInitialChamado">
                {{ $t('helper.modal.btn_submit') }}
            </button>
        </div>
    </div>
</b-modal>
</template>

<script>
import TicketService from "@/services/resources/TicketService";
const serviceTicket = TicketService.build();
import notify from "@/services/libs/notificacao";

export default {
    components: {},
    data() {
        return {
            titleChamado: "",
            descricaoChamado: "",
            empty: false,
        }
    },
    computed: {
        isMobile() {
            return this.client.width < 576;
        },
        theme() {
            return this.$store.state.globalSettings.theme;
        },
        clubVersion() {
            return this.$store.state.globalSettings.clubVersion;
        },
        isTitleEmpty() {
            return this.empty && !this.titleChamado;
        },
        isDescriptionEmpty() {
            return this.empty && !this.descricaoChamado;
        },
        descricaoChamadoExcedido() {
            return this.descricaoChamado.length > 255;
        }
    },
    methods: {
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'modal-new-helper-V2', '#btnShow')
        },
        saveInitialChamado() {
            if (!this.titleChamado) {
                this.empty = true;
                notify('erro', "Por favor, preencha o campo do Título.");
                return;
            }
            if (!this.descricaoChamado) {
                this.empty = true;
                notify('erro', "Por favor, preencha o campo da Mensagem.");
                return;
            }
            this.loading = true;
            serviceTicket
                .create({
                    subject: this.titleChamado
                })
                .then((resp) => {
                    let data = {
                        id: resp.id + "/message",
                        message: this.descricaoChamado,
                    };
                    serviceTicket
                        .postID(data)
                        .then((resp) => {
                            this.$root.$emit(
                                "bv::hide::modal",
                                "modal-new-helper-V2",
                                "#btnShow"
                            );
                            this.descricaoChamado = "";
                            this.imageSelected = {};
                            this.images = [];
                            this.loading = false;
                            this.titleChamado = '';
                        })
                        .catch((err) => {
                            this.loading = false;
                            var error = JSON.parse(err.response.data);
                            var msg = '';
                            for (var indice in error) {
                                msg += error[indice][0] + "<br>";
                            }
                            if (msg !== '') {
                                notify('erro', msg);
                            }
                        });
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        createMsgImg() {
            this.loading = true;
            let data = {
                id: this.rowSelected.id + "/message",
                media: this.imageSelected.path,
            };
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.view(this.rowSelected);
                    this.descricaoChamado = "";
                    this.imageSelected = {};
                    this.images = [];
                    this.$root.$emit("bv::hide::modal", "modal-new-helper-V2", "#btnShow");
                    this.loading = false;
                })
                .catch((err) => {
                    //console.log(err);
                    this.loading = false;
                });
        },
        createMsg() {
            this.loading = true;
            let data = {
                id: this.rowSelected.id + "/message",
                message: this.descricaoChamado,
            };
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.view(this.rowSelected);
                    this.descricaoChamado = "";
                    this.imageSelected = {};
                    this.images = [];
                    this.$root.$emit("bv::hide::modal", "modal-new-helper-V2", "#btnShow");
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
    mounted() {}
}
</script>

<style lang="scss">
.modal-content {
    background-color: var(--background-v2) !important;
}

.border-radius {
    border-radius: 20px;
}

.inputTitle {
    width: 100%;
    height: 45px;
    background: var(--background2-v2) !important;
    border-radius: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: var(--fontcolor2-v2);
    border: none;
}

.textAreaModal {
    width: 100%;
    background: var(--background2-v2) !important;
    border-radius: 20px;
    border: none !important;
    padding: 5px 25 !important;
    padding-right: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 13px !important;
    color: var(--fontcolor-v2) !important;
    height: 140px !important;
    overflow: hidden !important;
    resize: none !important;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 16px;
}

.btnSubmit {
    width: 146px;
    height: 45px;
    border: none;
    background: #01CAA6;
    border-radius: 50px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.title {
    font-family: 'Montserrat Alternates';
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--fontcolor-v2);
    margin-top: 16px;
    margin-bottom: 32px;
}

.spaceFooter {
    display: flex;
    justify-content: flex-end;
}

.groupModal {
    margin-top: 24px;
    margin-bottom: 24px;
}

.modalContent {
    padding: 4px;
    width: 100%;
}

.close {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 40px;
    opacity: 1;
    text-shadow: inherit;
    font-weight: lighter;
    margin-top: 9px;
    cursor: pointer;
    color: var(--fontcolor-v2);
    background: var(--background2-v2);
}

.border-red {
    outline: none !important;
    border-color: #ff0e0e !important;
    box-shadow: 0 0 5px #ff0e0e !important;
    border: none !important;
}

.countLength {
    position: absolute;
    bottom: 2px;
    right: 10px;
    font-size: 10px;
    color: #01CAA6;
    margin-bottom: 0;
}
</style>
